<template>
  <v-container>
    <h1>Analytics</h1>
    <p>Coming soon: Charts and graphs and all of that fun stuff with statistical insights that are both cool and hip.</p>
  </v-container>
</template>

<script>
export default {
  name: "Analytics",
}
</script>